import React from "react";
import {Helmet} from "react-helmet";

const SignatureStyle = {
    borderSpacing: "20px",
};
const ImageWrapper = {
    width: "215px",
    marginLeft: "20px",
};
const Image = {
    width: "208px",
    margin: "auto",
    display: "block",
};
const TextWrapper = {
    marginLeft: "20px",
    fontFamily: "Arial",
    lineHeight: "1.45",
};
const Name = {
    fontSize: "17px",
    margin: "0",
    fontWeight: "bold",
    lineHeight: "1.27",
};
const Job = {
    margin: "12px 0 5px 0",
    fontSize: "12px",
    lineHeight: "1.14",
    letterSpacing: "1.4px",
    color: "#969696",
    textTransform: "uppercase",
};

const Pole = {
    margin: "10px 0 0 0",
    fontSize: "13px",
    lineHeight: "1.15",
    color: "#67267a",
    fontWeight: "bold",
};

const Link = {
    color: "#67267a",
    fontWeight: "bold",
};

const Mail = {
    margin: "10px 0px 0px 0",
    fontSize: "13px",
    lineHeight: "1.38",
    textDecoration: "none",
};

const MailLink = {
    textDecoration: "none",
    color: "black",
};

const StandardURL = {
    margin: "12px 0 12px 0",
    fontSize: "13px",
};

const Slogan = {
    margin: "10px 0 10px 0",
    fontSize: "13px",
    fontStyle: "italic",
    lineHeight: "1.38",
    color: "#67267a",
    fontWeight: "bold",
};

const Phone = {
    fontWeight: "bold",
    fontSize: "13px",
    color: "black",
    margin: "0px 0 10px 0",
};
const PhoneLink = {
    textDecoration: "none",
    color: "black",
};

function telWithoutSpaces(number) {
    return number.replace(/\s/g, "");
}

const Signature = ({pageContext}) => {
    const {signature} = pageContext;
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    {signature.name.firstname} {signature.name.lastname} | Signature mail
                </title>
            </Helmet>
            <table className="signature" style={SignatureStyle}>
                <tbody>
                <tr>
                    <td className="image__wrapper" style={ImageWrapper}>
                        <a href={`https://${signature.url}`}>
                            <img
                                src={`https://files.myclientisrich.com/${signature.type}/${signature.imagePath}`}
                                width="208"
                                height="291"
                                alt="Custy"
                                style={Image}
                            />
                        </a>
                    </td>
                    <td className="text__wrapper" style={TextWrapper}>
                        <h1 style={Object.assign({}, Name, {color: "black"})}>
                            {signature.name.firstname} {signature.name.lastname}
                        </h1>

                        <h2 style={Job}>{signature.job}</h2>

                        <p style={Pole}>{signature.pole}</p>

                        <p style={Mail}>
                            <a href={`mailto:${signature.mail}`} style={MailLink}>
                                {" "}
                                {signature.mail}
                            </a>
                        </p>

                        <p style={Phone}>
                            <a
                                href={`tel:${telWithoutSpaces(signature.tel.fixe)}`}
                                style={PhoneLink}
                            >
                                Tél. {signature.tel.fixe}
                            </a>
                            <br/>
                            {signature.tel.fax ? (
                                <>
                                    <a
                                        href={`tel:${telWithoutSpaces(signature.tel.port)}`}
                                        style={PhoneLink}
                                    >
                                        Fax : {signature.tel.port}
                                    </a>
                                    <br/>
                                </>
                            ) : null}
                        </p>

                        <p style={Slogan}>
                            Accompagner les professionnels
                            <br/>
                            de l’assurance dans
                            <br/>
                            leur transformation digitale !
                        </p>

                        <p style={StandardURL}>
                            <a href={`https://${signature.url}`} style={Link}>
                                {signature.url}
                            </a>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default Signature;
